@media (max-width:768px) {
  .footer {
    display: none;
  }
  .footermob {
    display: block;
  }
}

@media (min-width:769px) {
  .footer {
    display: block;
  }
  .footermob {
    display: none;
  }
}

.navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 60%;
  color: #003a70
}

.button {
  background-color: #d1eabc;
  color: #003a70;
  border: none;
  cursor: pointer;
}

/* Style the links inside the navigation bar */
.button a {
  color: #003a70;
  text-decoration: none;
  width: 100%;
}

/* Change the color of links on hover */
.button a:hover {
  background-color: #c2e5a6;
  color: black;
}

/* Add a color to the active/current link */
.navbar a.active {
  background-color: #04AA6D;
  color: white;
}

/* Put address on the right hand side of the footer */
.navbaraddress {
  color: #003a70;
  text-align: left;
  padding-right: 20px;
}

/* Make the logo center aligned */
.navbarlogo {
  display: flex;
  justify-content: center; /* Center horizontally */
}

.columnContainerfoot {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  background-color: #d1eabc;
  padding: 20px;
  width: 100%;
}

.column {
  flex-shrink: 0;
  flex-basis: 33%;
}


/* mobile navbar */
.mobfoot {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right:2rem;
  background-color: #d1eabc;
  color: #003a70;
}

.navmob {
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
}

.contactmob {
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
