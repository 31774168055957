


@media (min-width: 769px) {
  .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  }
  .mapcontainer {
    width: 63%;
  }
  .historybuttoncontainer {
    flex-direction: column;
    max-width: 20rem;
    width: 15rem;
    margin-left: 5rem;
    box-sizing: border-box;
  }
  .history_map1 {
    width: 100%;
    height: 600px;
  }
  .white-space {
    width: 20rem;
  }
  .history_map {
    height: 600px;
    margin-left: 19.5em;
    margin-right: 19.5em;
  }
  .historyButtons {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-family: "Roboto";
    border: #003a70;
    font-weight: 300;
    background-color: #c2e5a6;
    color: #003a70;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 1rem;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  }
  .mapcontainer {
    width: 100%;
  }
  .historybuttoncontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 1rem auto;
  }
  .history_map1 {
    width: 100%;
    height: 600px;
  }
  .white-space {
    display:none;
  }
  .history_map {
    height: 600px;
  }
  .historyButtons {
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    font-family: "Roboto";
    border: #003a70;
    font-weight: 300;
    background-color: #c2e5a6;
    color: #003a70;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0.2rem;
  }
  .searchboxcontainer {
    text-align: center;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
  }
  .birdtablecontainer {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;    
    justify-content: center; /* Center horizontally */
    display: flex;
    align-items: center;
  }
  .birdtable {
    width:50%
  }
}

/* Remove the dots at the bottom of the carousel */
.control-dots {
  display: none;
}

.historyButton-active {
  background-color: #a7da7e; /* Change to your desired color */
  color: #003a70; /* Change to your desired text color */
}

@media (max-width: 768px) {
  .c-office-overview__map {
    height: 600px;
    margin-left: 0;
    margin-right: 0;
  }
  .history_map {
    height: 600px;
    margin-left: 0;
    margin-right: 0;
  }
}

.c-office-overview__map-selected-info__close {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (max-width: 768px) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pageHeaderPic {
  width: 100%;
  height: auto;
  max-height: 300px;
  position: relative;
  max-width: 100%;
}

.speciesImages {
  flex-wrap: wrap;
  gap: 3rem;
  display: flex;
  justify-content: center;
}

.photo {
  max-height: 20rem;
}
@media (min-width: 769px) {
  .infoList {
    justify-items: center;
    max-width: 24rem;
    margin-top: 0.5rem;
    list-style-position: outside;
  }
  .list3col {
    justify-items: center;
    max-width: 16rem;
    margin-top: 0.5rem;
    list-style-position: outside;
  }
}

@media (max-width: 768px) {
  .infoList {
    text-align: center;
    margin: 1rem;
  }
  .list3col {
    text-align: center;
    margin: 1rem;
  }
}

@media (min-width: 769px) {
  .columnContainer {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  .columnonelarge {
    flex-shrink: 0;
    flex-basis: 60%;
  }
  .columntwo {
    flex: 1;
  }
  .columntwo img {
    max-width: 100%;
  }
  .columnonesmall {
    flex-shrink: 0;
    flex-basis: 40%;
  }
  .columnonesmall img {
    max-width: 100%;
  }
}

/*font for main text*/
p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25em;
  text-align: left;
  font-weight: lighter;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.35em;
  margin-bottom: 0.5em;
}

i {
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  text-align: left;
  font-weight: lighter;
  font-style: italic;
}

.gap {
  margin-top: 3rem;
}

.medium_gap {
  margin-top: 2rem;
}

.small_gap {
  margin-top: 1rem;
}

/*font for the headings */
h1 {
  font-family:'Roboto',  sans-serif;
  font-size: 2em;
  font-weight: 300;
  text-align: center;
}

/*font for subheadings */
h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5em;
  text-align: left;
  font-weight: 400;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5em;
  font-weight: 250;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5em;
  font-weight: 250;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 0.5rem;
}

h5 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25em;
  font-weight: 100;
}

@media (max-width: 768px) {
  p {
    font-size: 1em;
  }
  h1 {
    font-size: 1.3em;
  }
  h2 {
    font-size: 1.15em;
    text-align: center;
  }
  h3 {
    font-size: 1em;
  }
  h4 {
    font-size: 1em;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* header */




@media all and (min-width: 769px) {
  .nav-area {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0px 20px;
    background-color: #d1eabc;
  }
  .main-menu-mobile-mode {
    display: none;
  }
  .logo {
    text-decoration: none;
    font-size: 15px;
    color: inherit;
    margin-right: 20px;
  }
}

@media all and (max-width: 768px) {
  .nav-area {
    display: none; /* Hide the desktop version on mobile */
  }
  .main-menu-mobile-mode {
    display: flex; /* Show the mobile version */
    background-color: #d1eabc;
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Align items at the beginning and end */
    max-height: fit-content;
    z-index: 1000;
  }
  .logo-mob {
    text-decoration: none;
    color: inherit;
    align-items: center;
    width: auto;
    padding-left: 0.25em;
  }
  .menu-button-container {
    padding-right: 0.5em;
  }
  .menu-button {
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    font-family: "Roboto";
    font-weight: 300;
    background-color: #c2e5a6;
    color: #003a70;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .mobile-menu-area {
  position: absolute;
  width: 100%;
  z-index: 100;
  align-items: left;
  right: 0;
  width: 75%;
  font-family: 'Roboto';
  }
  .mobile-dropdown {
    position: relative; 
    z-index: 1000;
    overflow-y: auto;
    top: 200;
  }
}

/* Header navbar */

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  position: absolute;
  right: 0;
  margin-right: 20px
}

.menu-items {
  position: relative;

}

.menu-items a {
  display: block;
  font-size: inherit;
  color: #003a70;
  text-decoration: none;
  background-color: #d1eabc;
}

.menu-items button {
  display: flex;
  align-items: right;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  background-color: #d1eabc;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: center;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #c2e5a6;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #d1eabc;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}

/* Form Css */

/* App.css */
.form-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}

.map-container {
  padding: 1rem;
  box-sizing: border-box;
  width: 63%; /* Set a specific width for the map container */
  height: 600px; /* Set the desired height for the map container */
}


.suggestions {
  position: absolute;
  z-index: 9999;
  border: 1px solid #999;
  background-color: #fff;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  width: 100%;
  border-radius: 0.5rem;
  font-family: 'Roboto';
  font-weight: lightero;
}

.suggestions li {
  padding: 0.5rem;
  width: 32.5%;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #003a70;
  color: #f98a3c;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

input {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 100%; /* Set the input width to 100% */
  margin-bottom: 0.5rem; /* Add some spacing between the inputs */
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem; /* Add margin above the button container */
  margin-bottom: 1rem; /* Add margin below the button container */
}

.button-container button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #003a70;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-container button:hover {
  background-color: #f98a3c;
  color: #fff;
}


/* testing photo/video gallery */
.thumbnail-link {
  display: inline-block;
  overflow: hidden;
  margin: 3px; /* Add some margin to separate thumbnails */
}

.thumbnail-image,
.thumbnail-video {
  width: auto;
  height: 150px;
}

.thumbnail-gallery {
  max-width: 100%;
}

.videothumbnails {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center; 
}


/* carousel */
.legendStyle {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 58, 112, 0.7); /* Adjust the background color and opacity as needed */
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 1;
}
.carouselfull {
  z-index: 1;
}

.custom-carousel .carousel .slider-wrapper {
  z-index: 1;
  max-height: 300px
}

.legend {
  background-color: #a7da7e;
  color: #003a70;
}

@media (min-width: 769px) {
  .frontpagetext {
    text-align: center;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .frontpagetext-inner {
    width: 59%;
  }
}

@media (max-width: 768px) {
  .frontpagetext {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;    
    justify-content: center; /* Center horizontally */
    display: flex;
    align-items: center;
  }
  .frontpagetext-inner {
    width:85%
  }
}




/* Camera traps */
.trapLinks {
  display: flex;
  flex-direction: row; 
  margin-left: 30px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.imageContainer {
  position: relative;
  margin-right: 20px;
  margin-top: 20px;
}

.banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 58, 112, 0.7); /* Adjust the background color and opacity as needed */
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 1;
}

.bannersmall {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 58, 112, 0.7); /* Adjust the background color and opacity as needed */
  color: white;
  padding: 5px;
  text-align: center;
  z-index: 1;
}

.linkbanner {
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-family: "Roboto";
  font-weight: 300;
  background-color: #c2e5a6;
  color: #003a70;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.goatcolumn {
  max-width: 240px;
}

.map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 77px); /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}



ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}


a.active {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .sidebarmob {
    display: block;
  }
  .sidebar {
    display: none;
  }
}

@media (min-width: 769px) {
  .sidebar {
    display: block;
    cursor: pointer;
  }
  .sidebarmob {
    display: none; 
  }
  .dykesvidcontainer {
    display: flex;
    align-items: flex-start;
  }
  .dykeswhitespace {
    width: 20.5%;
  }
  .dykesvidtext {
    max-width: 59%;
  }
  .dykesvid {
    max-width: 20.5%;
    display: flex-end;
    width: 85%;
  }
}

/* @media (max-width: 768px) {
  .carouselmob {
    display: block;
  }
  .carouselfull {
    display: none;
  }
}

@media (min-width: 769px) {
  .carouselfull {
    display: block;
  }
  .carouselmob {
    display: none; 
  }
} */

.big_space {
  height: 300px
}